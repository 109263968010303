import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import InsideHeader from '../components/insideheader'
import ReactMarkdown from 'react-markdown'
import DemoCarousel from '../components/democarousel'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Link } from "gatsby";
import Metatags from '../components/Metatags'

export default ({ data }) => {
  const page = data.dataJson
  return (
      <Layout>
      <Metatags
               title={page.meta.metatitle}
               keywords={page.meta.metakeywords}
               description={page.meta.metadescription}
               thumbnail={page.meta.metaimage}
               url={page.meta.metaurl}
               pathname={page.meta.metapathname}
           />
          <InsideHeader pageTitle={page.title} pageSubTitle={page.subTitle} pageBackground={page.titleBackground}/>
          <main className="main-content">


          <section className="section">
            <div className="container">

              <div className="row">
                <div className="col-md-4 mr-auto mb-4 mb-md-0">
                <Carousel showArrows={true} showThumbs={true}>
                      <div key="1">
                         <img src="https://cdn.humz.it/arman/room-8.jpg" />
                      </div>
                      <div key="2">
                         <img src="https://cdn.humz.it/arman/room-7.jpg" />
                      </div>
                </Carousel>
                </div>


                <div className="col-md-4">
                  <h4>Large Double Room</h4>
                  <ul>

                    <li>Includes Breakfast</li>
                    <li>In-room, smart, LED TVs, safes, Bluetooth speakers, bottled water</li>
                    <li>Wi-Fi throughout the premises</li>
                    <li>Choice of coffee or tea (self-service)</li>
                    <li>Refrigerator, microwave and stove</li>
                    <li>Access to the work space and lounge</li>
                  </ul>
                  <a className="fs-12 fw-600" href="/facilities">Read more <i class="fa fa-angle-right pl-1"></i></a>
                </div>


                <div className="col-md-4">
                  <h4>Introductory 10% discount</h4>



                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th><strong>Occupancy</strong></th>
                        <th><strong>With Breakfast</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Double</th>
                        <td><p>
                          <del>&#8377; 5500</del>
                          <span className="lead ml-5">&#8377; 4950</span>
                        </p></td>
                      </tr>
                      <tr>
                        <th scope="row">Single</th>
                        <td><p>
                          <del>&#8377; 5200</del>
                          <span className="lead ml-5">&#8377; 4680</span>
                        </p></td>
                      </tr>
                    </tbody>
                  </table>

                  <table class="table table-striped">
                    <tr>
                      <th scope="row">(Extra bed)</th>
                      <td><p>
                        <del>&#8377; 1500</del>
                        <span className="lead ml-5">&#8377; 1350</span>
                      </p></td>
                    </tr>

                  </table>

                  <form>

                    <div className="row">
                      <div className="col form-group">
                      <Link className="btn btn-lg btn-primary" to="/booknow">Book Now</Link>
                      </div>
                    </div>
                  </form>

                </div>


              </div>


              <div className="row">
                <div className="col-md-4 mr-auto mb-4 mb-md-0">
                <Carousel showArrows={true} showThumbs={true}>
                      <div key="1">
                         <img src="https://cdn.humz.it/arman/room-6.jpg" />
                      </div>
                      <div key="2">
                         <img src="https://cdn.humz.it/arman/room-2.jpg" />
                      </div>
                </Carousel>
                </div>


                <div className="col-md-4">
                  <h4>Double Room</h4>
                  <ul>

                    <li>Includes Breakfast</li>
                    <li>In-room, smart, LED TVs, safes, Bluetooth speakers, bottled water</li>
                    <li>Wi-Fi throughout the premises</li>
                    <li>Choice of coffee or tea (self-service)</li>
                    <li>Refrigerator, microwave and stove</li>
                    <li>Access to the work space and lounge</li>
                  </ul>
                  <a className="fs-12 fw-600" href="/facilities">Read more <i class="fa fa-angle-right pl-1"></i></a>

                </div>


                <div className="col-md-4">
                  <h4>Introductory 10% discount</h4>

                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th><strong>Occupancy</strong></th>
                        <th><strong>With Breakfast</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Double</th>
                        <td><p>
                          <del>&#8377; 5000</del>
                          <span className="lead ml-5">&#8377; 4500</span>
                        </p></td>
                      </tr>
                      <tr>
                        <th scope="row">Single</th>
                        <td><p>
                          <del>&#8377; 4700</del>
                          <span className="lead ml-5">&#8377; 4230</span>
                        </p></td>
                      </tr>
                      </tbody>
                    </table>
                    <table class="table table-striped">
                      <tr>
                        <th scope="row">(Extra bed)</th>
                        <td><p>
                          <del>&#8377; 1500</del>
                          <span className="lead ml-5">&#8377; 1350</span>
                        </p></td>
                      </tr>

                  </table>


                  <form>

                    <div className="row">
                      <div className="col form-group">
                      <Link className="btn btn-lg btn-primary" to="/booknow">Book Now</Link>
                      </div>
                    </div>
                  </form>

                </div>


              </div>


            </div>
          </section>
          </main>
      </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    dataJson(fields: { slug: { eq: $slug } }) {
      title
      subTitle
      titleBackground
      contents {
         heading
         image
         content
         heading
       }
       meta {
           metadescription
       metakeywords
       metatitle
       metaimage
       metaurl
       metapathname
       }
    }
  }
`
